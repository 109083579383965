import React, { useState } from "react";
import IconBlack from "../images/icon-black.png";
function Hero(props) {
    const [show, setShow] = useState(false);
    return (
        <div className=" pb-12 bg-gray-100">
            {/* Code block starts */}

                <div className="w-full lg:px-6 px-3">
                    <div className="lg:mt-8 mt-4 relative rounded-lg bg-gradient-to-r from-accent to-[#20a264] mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64">
                        <img className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg" />
                        <img className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg" />
                        <div className=" mb-5 sm:mb-10">
                            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight">{props.header}</h1>
                        </div>
                        {/*<img className={"w-28 pb-10"} src={IconBlack} alt={"icon"}/>*/}
                        <div className="flex justify-center items-center lg:mb-8 mb-4 sm:mb-20">
                            <a href={props.href}><button className="hover:text-white hover:bg-transparent lg:text-xl hover:border-white border bg-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-accent	focus:ring-white rounded text-accent px-4 sm:px-8 py-1 sm:py-3 text-sm">Get Started</button></a>
                            <a href={"#content-start"}><button className="hover:bg-white hover:text-accent lg:text-xl hover:border-accent ml-3 sm:ml-6 bg-transparent transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-accent focus:ring-white hover:bg-accent-800 rounded border border-white text-white px-4 sm:px-8 py-1 sm:py-3 text-sm">Learn More</button></a>
                        </div>
                    </div>
                    <div className=" mx-auto flex justify-center md:-mt-56 -mt-20 sm:-mt-40">
                        <div className="relative px-2 lg:px-8">
                            {props.children}
                        </div>
                    </div>
                </div>

            {/* Code block ends */}
        </div>

    );
}

export default Hero;
