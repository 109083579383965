import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";
import schedule from "../images/youth-sched.png";
import scheduleUpdated from "../images/schedule-updated.png";
import OpenGraphLogo from "../images/open-graph-logo.png";

function YouthGroupClassUpdated(){
    return(
        <div>
            <Helmet>
                <title>Youth Athletic Development | Long-Term Athletic Training</title>
                <meta name="description" content="Expert youth athletic development focused on foundational movement skills, coordination & balance, and safe progression protocols. Build athletic confidence through age-appropriate strength training." />
                <meta property="og:title" content="Youth Athletic Development | Building Future Athletes" />
                <meta property="og:description" content="Comprehensive athletic development program for youth focusing on core strength and motor skill development" />
                <meta property="og:image" content={OpenGraphLogo} />
                <meta name="keywords" content="youth athletic development, long-term athletic development, foundational movement skills, coordination and balance, speed and reaction time, safe progression protocols, age-appropriate strength, team sport preparation, athletic confidence building, motor skill development, core strength" />
            </Helmet>
            <Nav/>
            <Hero href={"/contact"} header={"Youth Athletic Development Program"}>
                <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Our long-term athletic development program helps young athletes go from good to great. Through foundational movement skills and age-appropriate strength training, your athlete will develop coordination & balance, enhance speed & reaction time, and build core strength - all while following safe progression protocols that keep them protected.</p>
                    
                    <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/vUgz1roQhWg"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    
                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started!</button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more!</button></a>
                        </div>
                    </div>

                    <h2 className={"text-3xl text-center py-14"}>Training Schedule (subject to change)</h2>
                    <img src={scheduleUpdated} alt={'training schedule'}/>

                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>According to the NCAA the most common injuries seen in volleyball are ligament sprains and muscle strains (soft tissue). Our program emphasizes motor skill development and proper team sport preparation, designed with <span className={"font-bold italic"}>durability</span> as the number one priority.</p>

                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Experience what it takes to become an elite athlete through our athletic confidence building approach. From understanding recovery timing to mastering foundational movement skills, we teach proper techniques that ensure safety and peak performance.</p>

                    <div className="relative lg:w-[32rem] lg:h-[18rem] mx-auto pt-[56.25%] lg:pt-0 mb-14">
                        <iframe className="absolute inset-0 w-full h-full lg:h-[18rem]" src="https://www.youtube.com/embed/xycjt12igYg"></iframe>
                    </div>

                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started!</button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more!</button></a>
                        </div>
                    </div>

                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 pt-4 mx-auto"}>Athletic development in areas like speed & reaction time, coordination & balance, and core strength is built over time through proven safe progression protocols and proper adaptation to physical demands.</p>

                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}><span className={"font-bold italic"}>Too much too soon or inconsistency can lead to injury.</span> Our age-appropriate strength training ensures every athlete we work with is <span className={"font-bold italic"}>successful</span> and has the <span className={"font-bold italic"}>opportunity</span> to develop safely and effectively.</p>

                    <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>We recommend that athletes dedicate 1 hour twice a week to this program for a minimum of 8 weeks. This allows proper motor skill development and measurable results. Our long-term athletic development program builds your child's foundational movement skills and prepares them for success in team sports while complementing their on-field training.</p>

                    <div className={"lg:flex lg:flex-row lg:justify-center pb-20"}>
                        <div className={"lg:flex lg:flex-row lg:justify-center "}>
                            <a className={"lg:px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started!</button></a>
                            <a className={"lg:px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more!</button></a>
                        </div>
                    </div>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default YouthGroupClassUpdated